import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, noIndex, job }) => {
	const { pathname } = useLocation()
	const { site } = useStaticQuery(query)

	const { defaultTitle, defaultDescription, siteUrl, defaultImage } =
		site.siteMetadata

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		// TODO Warum verschwindet das / ?
		image: `${siteUrl}/${defaultImage}`,
		url: `${siteUrl}/${pathname}`,
		noIndex: noIndex || false,
		job: job || null,
	}

	return (
		<Helmet title={seo.title} htmlAttributes={{ lang: "de" }}>
			{noIndex && <meta name="robots" content="noindex" />}
			<meta name="description" content={seo.description} />
			<meta name="image" content={seo.image} />

			{seo.url && <meta property="og:url" content={seo.url} />}

			{/* TODO Think about Type */}
			<meta property="og:type" content="website" />

			{seo.title && <meta property="og:title" content={seo.title} />}

			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			{seo.image && <meta property="og:image" content={seo.image} />}

			<meta name="twitter:card" content="summary_large_image" />

			<meta name="twitter:creator" content="internejobs" />

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{seo.description && (
				<meta name="twitter:description" content={seo.description} />
			)}

			{seo.image && <meta name="twitter:image" content={seo.image} />}
		</Helmet>
	)
}

export default Seo

Seo.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	noIndex: PropTypes.bool,
	job: PropTypes.object,
}

Seo.defaultProps = {
	title: null,
	description: null,
	noIndex: false,
	job: null,
}

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				defaultDescription: description
				siteUrl: siteUrl
				defaultImage: image
			}
		}
	}
`
